import React from 'react'

export default function Discord({ className }) {
  return (
    <svg className={className} viewBox="0 0 32 36" width={32} height={36} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.746 0h24.49a3.768 3.768 0 013.757 3.524l.007.198V36l-3.947-3.395-2.212-2.015-2.339-2.142.969 3.322H3.746c-1.998 0-3.637-1.564-3.74-3.524L0 28.048V3.722C0 1.736 1.575.108 3.548.005L3.746 0h24.49zm9.613 8.623l-.236-.217c-.374.005-2.658.111-5.064 1.887 0 0-2.631 4.702-2.631 10.494 0 0 1.535 2.614 5.574 2.741 0 0 .676-.799 1.224-1.489-2.32-.69-3.198-2.124-3.198-2.124s.183.127.512.309c.018 0 .036.018.073.036.055.037.11.055.164.091.457.254.914.454 1.334.617.75.309 1.645.581 2.687.78 1.37.255 2.979.346 4.733.019.86-.163 1.736-.4 2.65-.78.64-.237 1.352-.582 2.102-1.072 0 0-.914 1.47-3.308 2.142.548.672 1.206 1.453 1.206 1.453 4.039-.127 5.574-2.741 5.574-2.723 0-5.792-2.632-10.494-2.632-10.494-2.389-1.776-4.687-1.882-5.063-1.887h-.054l-.256.29c3.107.926 4.551 2.287 4.551 2.287a15.265 15.265 0 00-5.5-1.724 15.565 15.565 0 00-3.692.036c-.11 0-.202.018-.311.036-.64.073-2.193.29-4.149 1.144-.676.29-1.078.508-1.078.508l.017-.015c.166-.152 1.585-1.391 4.477-2.26l.294-.085-.183-.218zm5.995 6.481c1.023 0 1.864.89 1.864 1.997 0 1.108-.823 1.997-1.864 1.997-1.024 0-1.865-.89-1.865-1.997 0-1.107.823-1.997 1.865-1.997zm-6.671 0c1.042 0 1.882.89 1.864 1.997 0 1.108-.822 1.997-1.864 1.997-1.023 0-1.864-.89-1.864-1.997 0-1.107.822-1.997 1.864-1.997z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  )
}
