import React from 'react'

export default function Deco3(props) {
  return (
    <svg width={27} height={27} viewBox="0 0 27 27" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M2.485 9.73c-1.134 3.12 2.996 7.482 9.224 9.749 6.228 2.267 12.195 1.58 13.328-1.534a11.921 11.921 0 01-3.562 5.088c-5.077 4.26-12.646 3.598-16.906-1.479A11.995 11.995 0 012.485 9.73z"
          fill="#57B560"
        />
        <path
          d="M21.475 23.033c5.076-4.26 5.739-11.829 1.479-16.906C18.694 1.05 11.124.388 6.048 4.648.97 8.908.308 16.478 4.568 21.554c4.26 5.077 11.83 5.74 16.907 1.48zm3.562-5.088c-1.133 3.114-7.1 3.8-13.328 1.534-6.228-2.267-10.358-6.629-9.224-9.743"
          stroke="#57B560"
          strokeWidth={2}
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
