import React, { Component } from 'react'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import Reveal from 'react-reveal/Reveal'

import { Button, UploadFile } from '../'
import { Deco3, Deco4, Deco5 } from '../../svg'
import './HomeUpload.scss'

export default class HomeUpload extends Component {
  state = {
    files: [
      {
        name: 'documentName.html',
        status: 'uploading',
      },
      {
        name: 'documentName.html',
        status: 'processing',
      },
      {
        name: 'documentName.html',
        status: 'error',
      },
      {
        name: 'documentName.html',
        status: 'complete',
        url: 'https://siasky.net/file/GABFSexcieFGjxXOr6BEDZx8bkf9cVM87jJLF7SKqK7SHQ',
      },
    ],
  }

  handleDrop = acceptedFiles => {
    this.setState({
      files: [
        ...acceptedFiles.map(f => {
          return {
            name: f.path,
            status: 'uploading',
            url: 'https://siasky.net/file/GABFSexcieFGjxXOr6BEDZx8bkf9cVM87jJLF7SKqK7SHQ',
          }
        }),
        ...this.state.files,
      ],
    })
  }

  render() {
    return (
      <Reveal effect="active">
        <div className="home-upload">
          <div className="home-upload-box fadeInUp delay6">
            <Dropzone onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <>
                  <div
                    className={classNames('home-upload-dashed', { 'drop-active': isDragActive })}
                    {...getRootProps()}
                  >
                    <span className="home-upload-text">Drag &amp; drop your file(s) here to pin</span>
                    <Button>Browse</Button>
                  </div>
                  <input {...getInputProps()} className="offscreen" />
                </>
              )}
            </Dropzone>

            {this.state.files.length > 0 && (
              <div className="home-uploaded-files">
                {this.state.files.map((file, i) => {
                  return <UploadFile key={i} {...file} />
                })}
              </div>
            )}
          </div>

          <p className="bottom-text fadeInUp delay8">
            Once a file has been uploaded, a 46 byte link called a 'Skylink' is generated. That link can then be shared
            with anyone to fetch the file from Skynet.
          </p>

          <Deco3 className="deco-3 fadeInUp delay8" />
          <Deco4 className="deco-4 fadeInUp delay8" />
          <Deco5 className="deco-5 fadeInUp delay8" />
        </div>
      </Reveal>
    )
  }
}
