export default {
  hljs: {
    display: 'block',
    overflowX: 'auto',
    padding: '0.5em',
    background: '#171917',
    color: '#e6e1dc',
  },
  'hljs-comment': {
    color: '#bc9458',
    fontStyle: 'italic',
  },
  'hljs-quote': {
    color: '#bc9458',
    fontStyle: 'italic',
  },
  'hljs-keyword': {
    color: '#c26230',
  },
  'hljs-selector-tag': {
    color: '#c26230',
  },
  'hljs-string': {
    color: '#a5c261',
  },
  'hljs-number': {
    color: '#a5c261',
  },
  'hljs-regexp': {
    color: '#a5c261',
  },
  'hljs-variable': {
    color: '#a5c261',
  },
  'hljs-template-variable': {
    color: '#a5c261',
  },
  'hljs-subst': {
    color: '#519f50',
  },
  'hljs-tag': {
    color: '#e8bf6a',
  },
  'hljs-name': {
    color: '#e8bf6a',
  },
  'hljs-type': {
    color: '#da4939',
  },
  'hljs-symbol': {
    color: '#6d9cbe',
  },
  'hljs-bullet': {
    color: '#6d9cbe',
  },
  'hljs-built_in': {
    color: '#6d9cbe',
  },
  'hljs-builtin-name': {
    color: '#6d9cbe',
  },
  'hljs-attr': {
    color: '#6d9cbe',
  },
  'hljs-link': {
    color: '#6d9cbe',
    textDecoration: 'underline',
  },
  'hljs-params': {
    color: '#d0d0ff',
  },
  'hljs-attribute': {
    color: '#cda869',
  },
  'hljs-meta': {
    color: '#9b859d',
  },
  'hljs-title': {
    color: '#ffc66d',
  },
  'hljs-section': {
    color: '#ffc66d',
  },
  'hljs-addition': {
    backgroundColor: '#144212',
    color: '#e6e1dc',
    display: 'inline-block',
    width: '100%',
  },
  'hljs-deletion': {
    backgroundColor: '#600',
    color: '#e6e1dc',
    display: 'inline-block',
    width: '100%',
  },
  'hljs-selector-class': {
    color: '#9b703f',
  },
  'hljs-selector-id': {
    color: '#8b98ab',
  },
  'hljs-emphasis': {
    fontStyle: 'italic',
  },
  'hljs-strong': {
    fontWeight: 'bold',
  },
}
